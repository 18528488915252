<template>
    <div class="d-flex align-center my-4">
        <v-divider v-if="!left" color="black"></v-divider>
        <p id="text" :style="fontStyle" :class="[mx, 'mb-0', 'font-weight-bold']" v-html="text"></p>
        <v-divider v-if="!right" color="black"></v-divider>
    </div>
</template>
<script>
export default {
    props: {
        text: { type: String, default: '' },
        left: { type: Boolean, default: false },
        right: { type: Boolean, default: false },
        fontStyle: { type: Object, default: () => ({}) }
    },
    computed: {
        mx() {
            if(this.left) return 'mr-6';
            else if(this.right) return 'ml-6'; 
            else return 'mx-6';
        }
    }
}
</script>
<style scoped>
#text {
    font-size: 18px;
}
</style>