<template>
    <v-card id="card" :style="styles.card">
        <v-sheet id="title">
            case
            <span :style="styles.num" v-text="num"></span>
        </v-sheet>
        <v-sheet id="content">
            <v-row align="center" style="height:120px;">
                <v-col>
                    <slot name="description"></slot>
                </v-col>
            </v-row>
            <div id="scheme">
                <img :src="schemeFigSrc" :style="styles.schemeFig" />
            </div>
        </v-sheet>
    </v-card>
</template>
<script>
export default {
    props: {
        num: { type: [Number, String], default: '' },
        schemeFigSrc: { type: String, default: '' }
    },
    computed: {
        styles() {
            return {
                card: {
                    width: this.$vuetify.breakpoint.forPC ? '315px' : '100%',
                },
                title: {
                    width: this.$vuetify.breakpoint.forPC ? '315px' : '100%',
                },
                num: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '26px' : '18px',
                },
                schemeFig: {
                    width: this.$vuetify.breakpoint.forPC ? undefined : '100%',
                    maxWidth: this.$vuetify.breakpoint.forPC ? undefined : '400px',
                },
            }
        }
    }
}
</script>
<style scoped>
#card {
    width: 315px;
    text-align: center;
    font-weight: 700;
}
#title {
    background-color: #FCE263;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
}
#content {
    padding: 20px;
}
</style>