<template>
    <page-section class="prices">
        <page-section-title title="ご料金体系"></page-section-title>
        <div id="subtitle" :style="styles.subtitle">
            Tutti では、<br v-if="!$vuetify.breakpoint.forPC">業務内容や解決したい課題をお伺いし、<br>
            お客様に合った最適な料金をご提案いたします。<br>
            まずはお気軽にお問い合わせください。
        </div>
        <text-horizontal-line class="my-12" text="料金ケース例"></text-horizontal-line>
        <v-row>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 12">
                <price-card
                    num="1"
                    :scheme-fig-src="getSchemeFigSrcForIndex(1)"
                >
                    <template v-slot:description>
                        Tutti環境導入のみ
                    </template>
                </price-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 12">
                <price-card
                    num="2"
                    :scheme-fig-src="getSchemeFigSrcForIndex(2)"
                >
                    <template v-slot:description>
                        <span class="case-box">case 1</span>
                        ＋ ラベリングシステム<br>カスタム開発
                    </template>
                </price-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 12">
                <price-card
                    num="3"
                    :scheme-fig-src="getSchemeFigSrcForIndex(3)"
                >
                    <template v-slot:description>
                        <span class="case-box">case 2</span>
                        ＋ クラウドソーシングへの<br>ラベリング発注代行
                    </template>
                </price-card>
            </v-col>
        </v-row>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'
import TextHorizontalLine from './TextHorizontalLine.vue'
import PriceCard from './PriceCard.vue'

export default {
    components: { PageSection, PageSectionTitle, TextHorizontalLine, PriceCard },
    computed: {
        styles() {
            return {
                subtitle: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px',
                },
            }
        }
    },
    methods: {
        getSchemeFigSrcForIndex(index) {
            if(this.$vuetify.breakpoint.forPC)
                return require(`@/assets/web-figures/price-scheme-${index}.png`);
            else
                return require(`@/assets/web-figures/price-scheme-sp-${index}.png`);
        }
    }
}
</script>
<style scoped>
.prices {
    background-image: url('~@/assets/web-figures/background-grid.png');
    background-repeat: repeat;
    font-size: 16px;
}
.case-box {
    background-color: rgb(252, 226, 99);
    width: fit-content;
    padding: 5px 10px;
    margin: 0 auto 10px auto;
    border-radius: 5px;
}
#subtitle {
    text-align: center;
}
</style>
