<template>
    <v-sheet>
        <prices-first-view-section></prices-first-view-section>
        <prices-section></prices-section>
        <call-to-action-section></call-to-action-section>
        <installation-section></installation-section>
        <call-to-action-section></call-to-action-section>
        <footer-section></footer-section>
    </v-sheet>
</template>
<script>
import PricesFirstViewSection from '../components/PricesFirstViewSection.vue'
import PricesSection from '../components/PricesSection.vue'
import InstallationSection from '../components/InstallationSection.vue'
import CallToActionSection from '../components/CallToActionSection.vue'
import FooterSection from '../components/FooterSection.vue'

export default {
    components: {
        PricesFirstViewSection,
        PricesSection,
        InstallationSection,
        CallToActionSection,
        FooterSection,
    }
}
</script>