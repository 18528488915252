<template>
    <page-section id="fv" :style="styles.fv">
        <div style="position:relative;">
            <div id="title" :style="styles.title">
                料金・導入フロー
            </div>
            <div id="subtitle" :style="styles.subtitle">
                Tuttiの導入をご検討されている方向けに、<br>
                料金体系・導入までのフローをご紹介します。<br>
                弊社担当から直接詳細なご説明も可能です。<br>
                まずはお気軽にお問い合わせください。
            </div>

            <contact-button class="mt-12" :style="styles.contactButton"></contact-button>
        </div>
    </page-section>
</template>
<script>
import ContactButton from './ContactButton.vue'
import PageSection from './PageSection.vue'

export default {
    components: { PageSection, ContactButton },
    props: {
        logoSrcs: { type: Array, default: () => ([]) },
    },
    computed: {
        styles() {
            return {
              fv: {
                  paddingTop: this.$vuetify.breakpoint.forPC ? '80px!important' : '30px!important',
                  height: this.$vuetify.breakpoint.forPC ? '450px' : '210px',
                  marginBottom: this.$vuetify.breakpoint.forPC ? undefined : '100px',
              },
              title: {
                  fontSize: this.$vuetify.breakpoint.forPC ? '36px' : '24px'
              },
              subtitle: {
                  fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
              },
              figFv: this.$vuetify.breakpoint.forPC
                  ? {
                      position: 'absolute',
                      bottom: '-200px',
                      left: '400px',
                      right: '0',
                      margin: 'auto'
                  }
                  : {
                      width: '95%',
                      maxWidth: '400px',
                      position: 'absolute',
                      bottom: '-70px',
                      left: '0',
                      right: '0',
                      margin: 'auto'
                  },
              contactButton: this.$vuetify.breakpoint.forPC
                  ? {}
                  : {
                      position: 'absolute',
                      bottom: '-100px',
                      left: '0',
                      right: '0',
                      margin: 'auto'
                  }
            }
        }
    },
}
</script>
<style scoped>
#fv {
    background-image: url('~@/assets/web-figures/fv-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 80px!important;
}
#title {
    font-size: 36px;
    font-weight: 700;
}
#subtitle {
    padding-top: 10px;
}
</style>
