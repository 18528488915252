<template>
    <page-section class="grey lighten-4">
        <page-section-title title="Tutti 導入フロー"></page-section-title>

        <v-timeline dense>
            <v-timeline-item small fill-dot color="#FFE443">
                <v-row class="row-step" align="center">
                    <v-col class="col-step" :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <img src="@/assets/web-figures/installation-step-01.png" />
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <p class="step-title">お問い合わせ</p>
                        <p class="step-body">
                            Tuttiのツールとしてのご利用はもちろん、カスタムでのラベリングシステムの開発や、
                            データラベル収集の運用なども広く承っております。<br>
                            まずはお気軽にご相談ください。
                        </p>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item small fill-dot color="#FFE443">
                <v-row class="row-step" align="center">
                    <v-col class="col-step" :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <img src="@/assets/web-figures/installation-step-02.png" />
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <p class="step-title">ヒアリング・ご提案</p>
                        <p class="step-body">
                            貴社の課題やご要望を伺いながら、最適なプランをご提案いたします。
                        </p>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item small fill-dot color="#FFE443">
                <v-row class="row-step" align="center">
                    <v-col class="col-step" :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <img src="@/assets/web-figures/installation-step-03.png" />
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.forPC ? false : 12">
                        <p class="step-title">ご発注</p>
                        <p class="step-body">
                            Tuttiをかんたんにアクセス可能な環境にてご用意いたします。<br>
                            ご利用開始後も使い方やシステム開発をサポートいたします。
                        </p>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'

export default {
  components: { PageSection, PageSectionTitle },
}
</script>
<style scoped>
.theme--light.v-timeline:before {
    background: #FFE443;
}
.v-timeline:before {
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 4px;
}
.row-step {
    padding: 20px 0;
}
.col-step {
    flex-grow: initial!important;
    text-align: center;
}
.step-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
}
.step-body {
    margin: 0;
}
.v-timeline {
    margin-left: -40px;
}
</style>
